import React, { lazy } from "react";

const
	viewComponents = {
		PageNotFound: lazy(() => import("../views/404/index")),
		HomePage: lazy(() => import("../views/App/App")),
		RepayingYourLoanLanding: lazy(() => import("../views/manage/repaying-your-loan")),
		RepayingYourLoanPaymentProcessing: lazy(() => import("../views/manage/repaying-your-loan/payment-processing")),
		RepayingYourLoanLoanPayoff: lazy(() => import("../views/manage/repaying-your-loan/loan-payoff")),
		RepayingYourLoanPaidAhead: lazy(() => import("../views/manage/repaying-your-loan/paid-ahead")),
		RepayingYourLoanRepaymentsPlans: lazy(() => import("../views/manage/repaying-your-loan/repayment-plans")),
		TroublePayingLanding: lazy(() => import("../views/trouble-making-payments")),
		TroublePayingChangeDueDate: lazy(() => import("../views/trouble-making-payments/change-due-date")),
		TroublePayingLoanDefault: lazy(() => import("../views/trouble-making-payments/loan-default")),
		TroublePayingLoanDelinquency: lazy(() => import("../views/trouble-making-payments/loan-delinquency")),
		TroublePayingReducePayments: lazy(() => import("../views/trouble-making-payments/reduce-payments")),
		TroublePayingPostponePayments: lazy(() => import("../views/trouble-making-payments/postpone-payments")),
		TroublePayingBankruptcy: lazy(() => import("../views/trouble-making-payments/bankruptcy")),
		YourAccountLanding: lazy(() => import("../views/manage/your-account")),
		YourAccountMobileApp: lazy(() => import("../views/manage/your-account/mobile-app/index")),
		YourAccountMobileAppDeleteAccount: lazy(() => import("../views/manage/your-account/mobile-app/delete-account")),
		YourAccountDebtReliefOrgs: lazy(() => import("../views/manage/your-account/debt-relief-organizations")),
		YourAccountCreditReporting: lazy(() => import("../views/manage/your-account/credit-reporting")),
		YourAccountAccountAccess: lazy(() => import("../views/manage/your-account/account-access")),
		YourAccountAccountAuthorizations: lazy(() => import("../views/manage/your-account/account-authorizations")),
		YourAccountTaxes: lazy(() => import("../views/manage/your-account/taxes")),
		YourAccountInterest: lazy(() => import("../views/manage/your-account/interest")),
		FormsLanding: lazy(() => import("../views/manage/resources/forms/index")),
		FormsPrivate: lazy(() => import("../views/manage/resources/forms/private")),
		FormsPayment: lazy(() => import("../views/manage/resources/forms/payment")),
		FormsFederal: lazy(() => import("../views/manage/resources/forms/federal")),
		FormsForgivenessDischarge: lazy(() => import("../views/manage/resources/forms/forgiveness-discharge")),
		FormsOther: lazy(() => import("../views/manage/resources/forms/other")),
		BenefitsForServiceMembersLanding: lazy(() => import("../views/manage/benefits-for-servicemembers/index")),
		BenefitsForServiceMembersFederal: lazy(() => import("../views/manage/benefits-for-servicemembers/federal")),
		BenefitsForServiceMembersPrivate: lazy(() => import("../views/manage/benefits-for-servicemembers/private")),
		AboutPage: lazy(() => import("../views/about")),
		ManageLoansLanding: lazy(() => import("../views/manage")),
		ContactUsPage: lazy(() => import("../views/contact")),
		LoanForgivenessDischargeIndex: lazy(() => import("../views/manage/loan-forgiveness-discharge")),
		LoanDischargePage: lazy(() => import("../views/manage/loan-forgiveness-discharge/loan-discharge")),
		DeathPage: lazy(() => import("../views/manage/loan-forgiveness-discharge/death")),
		PageCenterX: lazy(() => import("../views/lenders/page-center")),
		LenderLanding: lazy(() => import("../views/lenders/index")),
		LenderLibrary: lazy(() => import("../views/lenders/library")),
		HigherEdMeetAlec: lazy(() => import("../views/higher-ed/index")),
		OnlineOrderSuppMat: lazy(() => import("../views/higher-ed/onlineordersuppmat")),
		TotalAndPermanentDisability: lazy(() => import("../views/manage/loan-forgiveness-discharge/total-and-permanent-disability")),
		TeacherLoanForgiveness: lazy(() => import("../views/manage/loan-forgiveness-discharge/teacher-loan-forgiveness")),
		WaysToPayLanding: lazy(() => import("../views/manage/repaying-your-loan/ways-to-pay/index")),
		WaysToPayOnline: lazy(() => import("../views/manage/repaying-your-loan/ways-to-pay/online")),
		WaysToPayDirectDebit: lazy(() => import("../views/manage/repaying-your-loan/ways-to-pay/direct-debit")),
		WaysToPayPhone: lazy(() => import("../views/manage/repaying-your-loan/ways-to-pay/phone")),
		WaysToPayMail: lazy(() => import("../views/manage/repaying-your-loan/ways-to-pay/mail")),
		WaysToPayThirdPartyBillPayer: lazy(() => import("../views/manage/repaying-your-loan/ways-to-pay/third-party-bill-payer")),
		WaysToPayMobileApp: lazy(() => import("../views/manage/repaying-your-loan/ways-to-pay/mobile-app")),
		IdrWaiver: lazy(() => import("../views/manage/ffel-program-loans/idr-waiver")),
		ResourcesLanding: lazy(() => import("../views/manage/resources/index")),
		ResourcesGlossary: lazy(() => import("../views/manage/resources/glossary")),
		UpdateAccountInfo: lazy(() => import("../views/manage/your-account/update-account-information")),
		SiteMap: lazy(() => import("../views/site-map/index")),
		EndorsersCosignersComakers: lazy(() => import("../views/manage/endorsers-cosigners-comakers")),
		ConsolidationPage: lazy(() => import("../views/manage/consolidation")),
		BillingLandingPage: lazy(() => import("../views/manage/understanding-your-bill/index")),
		BillingDirectDebitStatement: lazy(() => import("../views/manage/understanding-your-bill/direct-debit-statement")),
		BillingDirectInterestBill: lazy(() => import("../views/manage/understanding-your-bill/interest-bill")),
		BillingDirectMonthlyBill: lazy(() => import("../views/manage/understanding-your-bill/monthly-bill")),
		ReducedPaymentBill: lazy(() => import("../views/manage/understanding-your-bill/reduced-payment-bill")),
		InterestNotice: lazy(() => import("../views/manage/understanding-your-bill/interest-notice")),
		SystemUpgradeMigration: lazy(() => import("../views/manage/system-upgrade-migration")),
		Unsubscribe: lazy(() => import("../views/unsubscribe/index"))

	},
	routeDataArray = [
		{
			path: "/",
			element: <viewComponents.HomePage />
		},
		{
			path: "email/unsubscribe.htm",
			element: <viewComponents.Unsubscribe />,
			exclude: true
		},
		{
			label: "About",
			path: "about",
			children: [
				{
					index: true,
					element: <viewComponents.AboutPage />
				}
			]
		},
		{
			label: "Sitemap",
			path: "sitemap",
			children: [
				{
					index: true,
					element: <viewComponents.SiteMap />
				}
			]
		},
		{
			label: "Contact Us",
			path: "contact",
			children: [
				{
					index: true,
					element: <viewComponents.ContactUsPage />
				}
			]
		},
		{
			label: "Meet Alec",
			path: "higher-ed",
			children: [
				{
					index: true,
					element: <viewComponents.HigherEdMeetAlec />
				},
				{
					label: "Online Ordering of Program Materials",
					path: "onlineordersuppmat",
					children: [
						{
							index: true,
							element: <viewComponents.OnlineOrderSuppMat />
						}
					]
				}
			]
		},
		{
			label: "Meet Alec for Lenders",
			path: "lenders",
			children: [
				{
					index: true,
					element: <viewComponents.LenderLanding />
				},
				{
					label: "Lender Library",
					path: "library",
					children: [
						{
							index: true,
							element: <viewComponents.LenderLibrary />
						}
					]
				},
				{
					label: "PageCenterX",
					path: "page-center",
					children: [
						{
							index: true,
							element: <viewComponents.PageCenterX />
						}
					]
				}
			]
		},
		{
			label: "Manage Your Loans",
			path: "manage",
			children: [
				{
					index: true,
					element: <viewComponents.ManageLoansLanding />
				},
				{
					path: "ffel-program-loans/idr-waiver",
					label: "IDR, PSLF, & Potential Student Loan Debt Relief Opportunities ",
					children: [
						{
							index: true,
							element: <viewComponents.IdrWaiver />
						}
					]
				},
				{
					path: "repaying-your-loan",
					label: "Repaying Your Loan",
					children: [
						{
							index: true,
							element: <viewComponents.RepayingYourLoanLanding />
						},
						{
							path: "payment-processing",
							label: "Payment Processing",
							children: [
								{
									index: true,
									element: <viewComponents.RepayingYourLoanPaymentProcessing />
								}
							]
						},
						{
							path: "loan-payoff",
							label: "Loan Payoff",
							children: [
								{
									index: true,
									element: <viewComponents.RepayingYourLoanLoanPayoff />
								}
							]
						},
						{
							path: "paid-ahead",
							label: "Paid Ahead",
							children: [
								{
									index: true,
									element: <viewComponents.RepayingYourLoanPaidAhead />
								}
							]
						},
						{
							path: "repayment-plans",
							label: "Repayment Plans",
							children: [
								{
									index: true,
									element: <viewComponents.RepayingYourLoanRepaymentsPlans />
								}
							]
						},
						{
							path: "ways-to-pay",
							label: "Ways to Pay",
							children: [
								{
									index: true,
									element: <viewComponents.WaysToPayLanding />
								},
								{
									path: "online",
									label: "Paying Online",
									children: [
										{
											index: true,
											element: <viewComponents.WaysToPayOnline />
										}
									]
								},
								{
									path: "direct-debit",
									label: "Direct Debit",
									children: [
										{
											index: true,
											element: <viewComponents.WaysToPayDirectDebit />
										}
									]
								},
								{
									path: "phone",
									label: "Phone",
									children: [
										{
											index: true,
											element: <viewComponents.WaysToPayPhone />
										}
									]
								},
								{
									path: "mail",
									label: "Mail",
									children: [
										{
											index: true,
											element: <viewComponents.WaysToPayMail />
										}
									]
								},
								{
									path: "third-party-bill-payer",
									label: "Third-Party Bill-Pay Services",
									children: [
										{
											index: true,
											element: <viewComponents.WaysToPayThirdPartyBillPayer />
										}
									]
								},
								{
									path: "mobile-app",
									label: "Mobile App",
									children: [
										{
											index: true,
											element: <viewComponents.WaysToPayMobileApp />
										}
									]
								}
							]
						}
					]
				},
				{
					path: "resources",
					label: "Resources",
					children: [
						{
							index: true,
							element: <viewComponents.ResourcesLanding />
						},
						{
							path: "glossary",
							label: "Glossary of Financial Aid Terms",
							children: [
								{
									index: true,
									element: <viewComponents.ResourcesGlossary />
								}
							]
						},
						{
							path: "forms",
							label: "Forms",
							children: [
								{
									index: true,
									element: <viewComponents.FormsLanding />
								},
								{
									path: "private",
									label: "Private Education Loan Deferment & Forbearance Forms",
									children: [
										{
											index: true,
											element: <viewComponents.FormsPrivate />
										}
									]
								},
								{
									path: "payment",
									label: "Payment Related Forms",
									children: [
										{
											index: true,
											element: <viewComponents.FormsPayment />
										}
									]
								},
								{
									path: "federal",
									label: "Federal Loan Deferment & Forbearance Forms",
									children: [
										{
											index: true,
											element: <viewComponents.FormsFederal />
										}
									]
								},
								{
									path: "forgiveness-discharge",
									label: "Forgiveness & Discharge Forms",
									children: [
										{
											index: true,
											element: <viewComponents.FormsForgivenessDischarge />
										}
									]
								},
								{
									path: "other",
									label: "Other Account Related Forms",
									children: [
										{
											index: true,
											element: <viewComponents.FormsOther />
										}
									]
								}
							]
						}
					]
				},
				{
					path: "your-account",
					label: "Your Account",
					children: [
						{
							index: true,
							element: <viewComponents.YourAccountLanding />
						},
						{
							path: "mobile-app",
							label: "Mobile Application",
							children: [
								{
									index: true,
									element: <viewComponents.YourAccountMobileApp />
								},
								{
									path: "delete-account",
									label: "Delete Account",
									children: [
										{
											index: true,
											element: <viewComponents.YourAccountMobileAppDeleteAccount />
										}
									]
								}
							]
						},
						{
							path: "account-access",
							label: "Account Access",
							children: [
								{
									index: true,
									element: <viewComponents.YourAccountAccountAccess />
								}
							]
						},
						{
							path: "debt-relief-organizations",
							label: "Beware of Debt Relief Organizations",
							children: [
								{
									index: true,
									element: <viewComponents.YourAccountDebtReliefOrgs />
								}
							]
						},
						{
							path: "taxes",
							label: "Taxes",
							children: [
								{
									index: true,
									element: <viewComponents.YourAccountTaxes />
								}
							]
						},
						{
							path: "credit-reporting",
							label: "Credit Reporting",
							children: [
								{
									index: true,
									element: <viewComponents.YourAccountCreditReporting />

								}
							]
						},
						{
							path: "update-account-information",
							label: "Update Your Account Information",
							children: [
								{
									index: true,
									element: <viewComponents.UpdateAccountInfo />
								}
							]
						},
						{
							path: "account-authorizations",
							label: "Account Authorizations",
							children: [
								{
									index: true,
									element: <viewComponents.YourAccountAccountAuthorizations />
								}
							]
						},
						{
							path: "interest",
							label: "Interest",
							children: [
								{
									index: true,
									element: <viewComponents.YourAccountInterest />
								}
							]
						}
					]
				},
				{
					path: "consolidation",
					label: "Federal Loan Consolidation",
					children: [
						{
							index: true,
							element: <viewComponents.ConsolidationPage />
						}
					]
				},
				{
					path: "benefits-for-servicemembers",
					label: "Benefits for Servicemembers",
					children: [
						{
							index: true,
							element: <viewComponents.BenefitsForServiceMembersLanding />
						},
						{
							path: "federal",
							label: "Benefits for Servicemembers with Federal Loans",
							children: [
								{
									index: true,
									element: <viewComponents.BenefitsForServiceMembersFederal />
								}
							]
						},
						{
							path: "private",
							label: "Benefits for Servicemembers with Private Education Loans",
							children: [
								{
									index: true,
									element: <viewComponents.BenefitsForServiceMembersPrivate />
								}
							]
						}
					]
				},
				{
					path: "endorsers-cosigners-comakers",
					label: "Endorsers, Co-signers & Co-makers",
					children: [
						{
							index: true,
							element: <viewComponents.EndorsersCosignersComakers />
						}
					]
				},
				{
					path: "loan-forgiveness-discharge",
					label: "Loan Forgiveness and Discharge",
					children: [
						{
							index: true,
							element: <viewComponents.LoanForgivenessDischargeIndex />
						},
						{
							path: "loan-discharge",
							label: "Loan Discharge",
							children: [
								{
									index: true,
									element: <viewComponents.LoanDischargePage />
								}
							]
						},
						{
							path: "death",
							label: "Death",
							children: [
								{
									index: true,
									element: <viewComponents.DeathPage />
								}
							]
						},
						{
							path: "total-and-permanent-disability",
							label: "Total and Permanent Disability",
							children: [
								{
									index: true,
									element: <viewComponents.TotalAndPermanentDisability />
								}
							]
						},
						{
							path: "teacher-loan-forgiveness",
							label: "Teacher Loan Forgiveness Program",
							children: [
								{
									index: true,
									element: <viewComponents.TeacherLoanForgiveness />
								}
							]
						}
					]
				},
				{
					path: "understanding-your-bill",
					label: "Understanding Your Bills and Notices",
					children: [
						{
							index: true,
							element: <viewComponents.BillingLandingPage />
						},
						{
							path: "direct-debit-statement",
							label: "Direct Debit Statement",
							children: [
								{
									index: true,
									element: <viewComponents.BillingDirectDebitStatement />
								}
							]
						},
						{
							path: "monthly-bill",
							label: "Monthly Bill",
							children: [
								{
									index: true,
									element: <viewComponents.BillingDirectMonthlyBill />
								}
							]
						},
						{
							path: "interest-bill",
							label: "Interest Bill",
							children: [
								{
									index: true,
									element: <viewComponents.BillingDirectInterestBill />
								}
							]
						},
						{
							path: "reduced-payment-bill",
							label: "Reduced Payment Bill",
							children: [
								{
									index: true,
									element: <viewComponents.ReducedPaymentBill />
								}
							]
						},
						{
							path: "interest-notice",
							label: "Interest Notice",
							children: [
								{
									index: true,
									element: <viewComponents.InterestNotice />
								}
							]
						}
					]
				},
				{
					path: "system-upgrade-migration",
					label: "System Upgrade and Migration",
					children: [
						{
							index: true,
							element: <viewComponents.SystemUpgradeMigration />
						}
					],
					exclude: true
				}
			]
		},
		{
			path: "trouble-making-payments",
			label: "Trouble Making Payments",
			children: [
				{
					index: true,
					element: <viewComponents.TroublePayingLanding />
				},
				{
					path: "bankruptcy",
					label: "Bankruptcy",
					children: [
						{
							index: true,
							element: <viewComponents.TroublePayingBankruptcy />
						}
					]
				},
				{
					path: "change-due-date",
					label: "Change Your Due Date",
					children: [
						{
							index: true,
							element: <viewComponents.TroublePayingChangeDueDate />
						}
					]
				},
				{
					path: "postpone-payments",
					label: "Postpone Payments with a Deferment or Forbearance",
					children: [
						{
							index: true,
							element: <viewComponents.TroublePayingPostponePayments />
						}
					]
				},
				{
					path: "loan-default",
					label: "Loan Default",
					children: [
						{
							index: true,
							element: <viewComponents.TroublePayingLoanDefault />
						}
					]
				},
				{
					path: "loan-delinquency",
					label: "Loan Delinquency",
					children: [
						{
							index: true,
							element: <viewComponents.TroublePayingLoanDelinquency />
						}
					]
				},
				{
					path: "reduce-payments",
					label: "Reduce Your Payments",
					children: [
						{
							index: true,
							element: <viewComponents.TroublePayingReducePayments />
						}
					]
				}
			]
		},
		{
			path: "*",
			element: <viewComponents.PageNotFound />
		}
	];


export { routeDataArray, viewComponents };